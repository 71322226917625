import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function Header () {

    const urlSearch = new URLSearchParams(useLocation().search);
    const [origin, setOrigin] = useState("");

    useEffect(() => {
        if (urlSearch.get("source")) {
            setOrigin(urlSearch.get("source"));
        }

    }, []);

    return (<div className="mc-hero">
        <div className="uk-container">
            <h2 className="uk-animation-fade" style={{zIndex: 5}}>
                {origin === 'FDV2021' ? 'Testez en quelques clics la maturité digitale de votre entreprise' : 'Questionnaire Cybersécurité'}
            </h2>
            
            <div style={{zIndex: 5}}>
                {origin === 'FDV2021' ? <>
                    <p style={{position: 'relative', color: 'white', zIndex: 5}}>
                        Merci de votre présence au Rendez-vous des Entrepreneurs 2022.
                    </p>
                    <p style={{position: 'relative', color: 'white', zIndex: 5}}>
                        Silicom Group et CimArk ont mis en place un petit questionnaire, qui vous permettra, en quelques questions, de tester la maturité digitale et votre degré de préparation aux menaces en lien avec la cybersécurité. 
                        C’est gratuit et cela peut être très utile. N’hésitez pas à prendre quelques minutes pour le remplir et pour en discuter à l’interne de votre entreprise. Nous restons quant à nous à votre disposition pour toute aide ou tout projet en lien avec la cybersécurité de votre société.
                    </p>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <a href="https://silicom.group/" target="_blank">
                                <img style={{margin: '5px 10px 5px 0'}} src="images/logoSilicomGroup.png" />
                            </a>
                        </div>
                        <div style={{flex: 1, textAlign: 'center'}}>
                            <a href="https://www.cimark.ch" target="_blank">
                                <img style={{margin: '5px 0 5px 10px'}} src="images/logoCimark.png" />
                            </a>
                        </div>
                    </div>
                </> : null}

                <p style={{position: 'relative', color: 'white'}}>En remplissant ce questionnaire, vous pourrez estimer la maturité cyber de votre entreprise et votre résultat basé sur les recommandations du "National Institute of Standards and Technology" (NIST).</p>
            </div>
            {/*<div className="img-wrap"><img src="images/rocket_illustation.svg" className="uk-position-right uk-animation-slide-right" style={{zIndex: 0}}/></div>*/}
        </div>
    </div>);
}

export default Header;
